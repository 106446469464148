import GATSBY_COMPILED_MDX from "/codebuild/output/src086036051/src/fib-betty/content/blog/tener-un-buen-educador-financiero.mdx";
import React from "react";
import {Box, Container, Grid, styled, Typography} from "@mui/material";
import {MDXProvider} from "@mdx-js/react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {Icon} from "@iconify/react";
import Layout from "../components/Layout";
var LinkPost = styled(Link)(function (_ref) {
  var theme = _ref.theme;
  return {
    textDecoration: "none",
    color: "black"
  };
});
function PageTemplate(_ref2) {
  var data = _ref2.data, children = _ref2.children, pageContext = _ref2.pageContext;
  var previous = pageContext.previous, next = pageContext.next;
  var image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, null, React.createElement(Container, null, React.createElement(Typography, {
    variant: "h1",
    py: 6
  }, React.createElement("strong", null, data.mdx.frontmatter.title)), React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt,
    style: {
      maxHeight: "calc(60vh - 4rem)"
    }
  }), React.createElement(Grid, {
    container: true,
    gap: 1,
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    py: 3
  }, React.createElement(Grid, {
    item: true
  }, React.createElement(Grid, {
    container: true,
    gap: 1
  }, React.createElement(Icon, {
    icon: "ant-design:user-outlined",
    fontSize: "20px"
  }), React.createElement(Typography, {
    variant: "body1"
  }, data.mdx.frontmatter.author))), React.createElement(Grid, {
    item: true,
    gap: 1
  }, React.createElement(Grid, {
    container: true,
    gap: 1
  }, React.createElement(Icon, {
    icon: "akar-icons:calendar",
    fontSize: "20px"
  }), React.createElement(Typography, {
    variant: "body1"
  }, data.mdx.frontmatter.createdAd)))), React.createElement(MDXProvider, null, children), React.createElement(Grid, {
    container: true,
    justifyContent: "space-between",
    alignItems: "center",
    py: 4
  }, previous ? React.createElement(LinkPost, {
    to: previous.frontmatter.slug
  }, React.createElement(Grid, {
    container: true,
    alignItems: "center"
  }, React.createElement(Icon, {
    icon: "akar-icons:chevron-left",
    fontSize: "20px"
  }), React.createElement(Typography, {
    variant: "body1"
  }, "Art\xEDculo anterior"))) : React.createElement(Box, null), next ? React.createElement(LinkPost, {
    to: next.frontmatter.slug
  }, React.createElement(Grid, {
    container: true,
    alignItems: "center"
  }, React.createElement(Typography, {
    variant: "body1"
  }, "Siguiente art\xEDculo"), React.createElement(Icon, {
    icon: "akar-icons:chevron-right",
    fontSize: "20px"
  }))) : React.createElement(Box, null))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "2664593368";
